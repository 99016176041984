import React from 'react'
import remark from 'remark'
import remarkHTML from 'remark-html'
import remarkParse from 'remark-parse'
import Content, { HTMLContent } from '../components/Content'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { ContainerSVGT, HomeColor, MainFont, MinistriesColor, MinistriesName} from '../components/Sampler'
import { 
  squareSVGT,
  circleSVGT,
  distressedsquareSVGT,
  distressedcircleSVGT,
  ruffledcircleSVGT,
  scallopedrectangleSVGT,
  curvedsquareSVGT,
  bookmarkSVGT,
  ybbookmarkSVGT, 
  ybcircleSVGT, 
  ybcurvedSVGT, 
  ybdistressedcircleSVGT,
  ybdistressedsquareSVGT,
  ybruffledSVGT, 
  ybscallopedSVGT, 
  ybsquareSVGT 
} from '../../static/svgtext'

export const MinistriesPageTemplate = ({ 
  backgroundimage,
  firstMinistry,
  firstMinistryTitle,
  firstMinistryInfo,
  secondMinistry,
  secondMinistryTitle,
  secondMinistryInfo,
  thirdMinistry,
  thirdMinistryTitle,
  thirdMinistryInfo,
  fourthMinistry,
  fourthMinistryTitle,
  fourthMinistryInfo,
  fifthMinistry,
  fifthMinistryTitle,
  fifthMinistryInfo,
  sixthMinistry,
  sixthMinistryTitle,
  sixthMinistryInfo,
  seventhMinistry,
  seventhMinistryTitle,
  seventhMinistryInfo,
  eighthMinistry,
  eighthMinistryTitle,
  eighthMinistryInfo,
  ninthMinistry,
  ninthMinistryTitle,
  ninthMinistryInfo,
  tenthMinistry,
  tenthMinistryTitle,
  tenthMinistryInfo,
  contentComponent
}) => {
  const PageContent = contentComponent || Content
  const toHTML = value => remark()
    .use(remarkParse)
    .use(remarkHTML)
    .processSync(value)
    .toString()

  return (
    <div>
      {MinistriesName === 'Removed' ? 
        <div>
          <div
            className="full-width-image margin-top-0"
            style={{backgroundColor: 'white'}}
          >

            {/* Font Imports */}
            <link
              href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
              rel="stylesheet"
            ></link>
            <link
              href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
              rel="stylesheet"
            ></link>
            <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.cdnfonts.com/css/bahnschrift" rel="stylesheet"></link>

            <div
              style={{
                display: 'flex',
                height: '150px',
                lineHeight: '0.8',
                justifyContent: 'space-around',
                alignItems: 'left',
                flexDirection: 'column',
                width: ''
              }}
            >
              {/* BLANK WHITE CIRCLE SVG */}
              <Link to="/" title="Home">
                <div style={{ height: '300px' }}>
                  <div style={{
                    position: 'absolute',
                    marginTop: '72px',
                    width: '100%',
                    left: '0px',
                    textAlign: 'center',
                    fontSize: '3.4em',
                    color: 'white',
                    fontFamily: MainFont,
                    textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null,
                    fontWeight: MainFont === 'Maven Pro' ? 'bold' : null
                  }}>
                    Your<br />Church<br />Name
                  </div>
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    height="310px" viewBox="0 0 1563.000000 1525.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                      fill={HomeColor} stroke="none">
                      <path d={ContainerSVGT} />
                    </g>
                  </svg>
                </div>
              </Link>
            </div>
          </div>
          <div>
            <h1 style={{marginLeft: '-10px', fontFamily: MainFont, fontSize: '60px', textAlign: 'center'}}>...Oops.</h1>
            <p style={{ marginTop: '20px', fontFamily: 'Poppins', fontSize: '20px', textAlign: 'center' }}>You tried to go to a page<br></br>in our site that doesn't exist.</p>
            <p style={{ marginTop: '20px', marginBottom: '50px', fontFamily: 'Poppins', fontSize: '20px', textAlign: 'center' }}>To return to our home page,<br></br>click the banner above.</p>
          </div>
        </div>
      :
      <div>
        <div
          className="full-width-image margin-top-0"
          style={{
            backgroundImage: `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
              })`,
          }}
        >

          {/* Font Imports */}
          <link
            href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
            rel="stylesheet"
          ></link>
          <link
            href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
            rel="stylesheet"
          ></link>
          <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
          <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
          <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
          <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>
          <link href="https://fonts.cdnfonts.com/css/bahnschrift" rel="stylesheet"></link>

          {/* MINISTRIES IMAGE HEADING */}
          <div style={{ display: 'grid' }}>

            {/* Colored Style Container */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              height="310px" viewBox="0 0 1563.000000 1525.000000"
              preserveAspectRatio="xMidYMid meet"
              style={{
                gridRow: '1 / 1',
                gridColumn: '1 / 1',
              }}>

              <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                fill={MinistriesColor} stroke="none">
                <path d={ContainerSVGT} />
              </g>
            </svg>

            {/* "Ministries" Text */}
            <div style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: '3.2em',
              color: '#FFFFFF',
              fontFamily: MainFont,
              fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
              letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
            }}>
              <p style={{ marginBottom: '-25px' }}>GET</p>
              <p style={{ letterSpacing: '-0.2px' }}>INVOLVED</p>
            </div>
          </div>
        </div>

        {/* Google Fonts Import */}
        <link
          href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
          rel="stylesheet"
        ></link>

          <div className='ministries-margins'>

            {/* FIRST MINISTRY */}
            {firstMinistry ?
              <div className='ministries-category-box'>

                {/* Heading with Backing */}
                <div style={{ display: 'grid', }}>

                  {/* Backing */}
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="250px" viewBox="0 0 1035.000000 267.000000"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      gridRow: '1 / 1',
                      gridColumn: '1 / 1',
                    }}>

                    <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                      fill={MinistriesColor} stroke="none">
                      <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null} />
                    </g>
                  </svg>

                  {/* Text */}
                  <div style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                    alignSelf: 'center',
                    textAlign: 'center',
                    width: '250px',
                    fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '1.8em' : '2.5em',
                    color: '#FFFFFF',
                    marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                    lineHeight: null,
                    fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                    fontFamily: MainFont,
                    fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                    letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.03em' : null
                  }}>
                    {firstMinistryTitle}
                  </div>
                </div>

                <div className="ministries-category-info" style={{ color: MinistriesColor }}>
                  <PageContent className="content" content={toHTML(firstMinistryInfo)} />
                </div>
              </div>
              : null}

            {/* SECOND MINISTRY */}
            {secondMinistry ?
              <div className='ministries-category-box'>

                {/* Heading with Backing */}
                <div style={{ display: 'grid', }}>

                  {/* Backing */}
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="250px" viewBox="0 0 1035.000000 267.000000"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      gridRow: '1 / 1',
                      gridColumn: '1 / 1',
                    }}>

                    <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                      fill={MinistriesColor} stroke="none">
                      <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null} />
                    </g>
                  </svg>

                  {/* Text */}
                  <div style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                    alignSelf: 'center',
                    textAlign: 'center',
                    width: '250px',
                    fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '1.8em' : '2.5em',
                    color: '#FFFFFF',
                    marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                    lineHeight: null,
                    fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                    fontFamily: MainFont,
                    fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                    letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.03em' : null
                  }}>
                    {secondMinistryTitle}
                  </div>
                </div>

                <div className="ministries-category-info" style={{ color: MinistriesColor }}>
                  <PageContent className="content" content={toHTML(secondMinistryInfo)} />
                </div>
              </div>
              : null}

            {/* THIRD MINISTRY */}
            {thirdMinistry ?
              <div className='ministries-category-box'>

                {/* Heading with Backing */}
                <div style={{ display: 'grid', }}>

                  {/* Backing */}
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="250px" viewBox="0 0 1035.000000 267.000000"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      gridRow: '1 / 1',
                      gridColumn: '1 / 1',
                    }}>

                    <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                      fill={MinistriesColor} stroke="none">
                      <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null} />
                    </g>
                  </svg>

                  {/* Text */}
                  <div style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                    alignSelf: 'center',
                    textAlign: 'center',
                    width: '250px',
                    fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '1.8em' : '2.5em',
                    color: '#FFFFFF',
                    marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                    lineHeight: null,
                    fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                    fontFamily: MainFont,
                    fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                    letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.03em' : null
                  }}>
                    {thirdMinistryTitle}
                  </div>
                </div>

                <div className="ministries-category-info" style={{ color: MinistriesColor }}>
                  <PageContent className="content" content={toHTML(thirdMinistryInfo)} />
                </div>
              </div>
              : null}

            {/* FOURTH MINISTRY */}
            {fourthMinistry ?
              <div className='ministries-category-box'>

                {/* Heading with Backing */}
                <div style={{ display: 'grid', }}>

                  {/* Backing */}
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="250px" viewBox="0 0 1035.000000 267.000000"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      gridRow: '1 / 1',
                      gridColumn: '1 / 1',
                    }}>

                    <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                      fill={MinistriesColor} stroke="none">
                      <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null} />
                    </g>
                  </svg>

                  {/* Text */}
                  <div style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                    alignSelf: 'center',
                    textAlign: 'center',
                    width: '250px',
                    fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '1.8em' : '2.5em',
                    color: '#FFFFFF',
                    marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                    lineHeight: null,
                    fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                    fontFamily: MainFont,
                    fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                    letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.03em' : null
                  }}>
                    {fourthMinistryTitle}
                  </div>
                </div>

                <div className="ministries-category-info" style={{ color: MinistriesColor }}>
                  <PageContent className="content" content={toHTML(fourthMinistryInfo)} />
                </div>
              </div>
              : null}

            {/* FIFTH MINISTRY */}
            {fifthMinistry ?
              <div className='ministries-category-box'>

                {/* Heading with Backing */}
                <div style={{ display: 'grid', }}>

                  {/* Backing */}
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="250px" viewBox="0 0 1035.000000 267.000000"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      gridRow: '1 / 1',
                      gridColumn: '1 / 1',
                    }}>

                    <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                      fill={MinistriesColor} stroke="none">
                      <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null} />
                    </g>
                  </svg>

                  {/* Text */}
                  <div style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                    alignSelf: 'center',
                    textAlign: 'center',
                    width: '250px',
                    fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '1.8em' : '2.5em',
                    color: '#FFFFFF',
                    marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                    lineHeight: null,
                    fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                    fontFamily: MainFont,
                    fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                    letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.03em' : null
                  }}>
                    {fifthMinistryTitle}
                  </div>
                </div>

                <div className="ministries-category-info" style={{ color: MinistriesColor }}>
                  <PageContent className="content" content={toHTML(fifthMinistryInfo)} />
                </div>
              </div>
              : null}

            {/* SIXTH MINISTRY */}
            {sixthMinistry ?
              <div className='ministries-category-box'>

                {/* Heading with Backing */}
                <div style={{ display: 'grid', }}>

                  {/* Backing */}
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="250px" viewBox="0 0 1035.000000 267.000000"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      gridRow: '1 / 1',
                      gridColumn: '1 / 1',
                    }}>

                    <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                      fill={MinistriesColor} stroke="none">
                      <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null} />
                    </g>
                  </svg>

                  {/* Text */}
                  <div style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                    alignSelf: 'center',
                    textAlign: 'center',
                    width: '250px',
                    fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '1.8em' : '2.5em',
                    color: '#FFFFFF',
                    marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                    lineHeight: null,
                    fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                    fontFamily: MainFont,
                    fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                    letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.03em' : null
                  }}>
                    {sixthMinistryTitle}
                  </div>
                </div>

                <div className="ministries-category-info" style={{ color: MinistriesColor }}>
                  <PageContent className="content" content={toHTML(sixthMinistryInfo)} />
                </div>
              </div>
              : null}

            {/* SEVENTH MINISTRY */}
            {seventhMinistry ?
              <div className='ministries-category-box'>

                {/* Heading with Backing */}
                <div style={{ display: 'grid', }}>

                  {/* Backing */}
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="250px" viewBox="0 0 1035.000000 267.000000"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      gridRow: '1 / 1',
                      gridColumn: '1 / 1',
                    }}>

                    <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                      fill={MinistriesColor} stroke="none">
                      <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null} />
                    </g>
                  </svg>

                  {/* Text */}
                  <div style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                    alignSelf: 'center',
                    textAlign: 'center',
                    width: '250px',
                    fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '1.8em' : '2.5em',
                    color: '#FFFFFF',
                    marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                    lineHeight: null,
                    fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                    fontFamily: MainFont,
                    fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                    letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.03em' : null
                  }}>
                    {seventhMinistryTitle}
                  </div>
                </div>

                <div className="ministries-category-info" style={{ color: MinistriesColor }}>
                  <PageContent className="content" content={toHTML(seventhMinistryInfo)} />
                </div>
              </div>
              : null}

            {/* EIGHTH MINISTRY */}
            {eighthMinistry ?
              <div className='ministries-category-box'>

                {/* Heading with Backing */}
                <div style={{ display: 'grid', }}>

                  {/* Backing */}
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="250px" viewBox="0 0 1035.000000 267.000000"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      gridRow: '1 / 1',
                      gridColumn: '1 / 1',
                    }}>

                    <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                      fill={MinistriesColor} stroke="none">
                      <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null} />
                    </g>
                  </svg>

                  {/* Text */}
                  <div style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                    alignSelf: 'center',
                    textAlign: 'center',
                    width: '250px',
                    fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '1.8em' : '2.5em',
                    color: '#FFFFFF',
                    marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                    lineHeight: null,
                    fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                    fontFamily: MainFont,
                    fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                    letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.03em' : null
                  }}>
                    {eighthMinistryTitle}
                  </div>
                </div>

                <div className="ministries-category-info" style={{ color: MinistriesColor }}>
                  <PageContent className="content" content={toHTML(eighthMinistryInfo)} />
                </div>
              </div>
              : null}

            {/* NINTH MINISTRY */}
            {ninthMinistry ?
              <div className='ministries-category-box'>

                {/* Heading with Backing */}
                <div style={{ display: 'grid', }}>

                  {/* Backing */}
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="250px" viewBox="0 0 1035.000000 267.000000"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      gridRow: '1 / 1',
                      gridColumn: '1 / 1',
                    }}>

                    <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                      fill={MinistriesColor} stroke="none">
                      <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null} />
                    </g>
                  </svg>

                  {/* Text */}
                  <div style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                    alignSelf: 'center',
                    textAlign: 'center',
                    width: '250px',
                    fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '1.8em' : '2.5em',
                    color: '#FFFFFF',
                    marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                    lineHeight: null,
                    fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                    fontFamily: MainFont,
                    fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                    letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.03em' : null
                  }}>
                    {ninthMinistryTitle}
                  </div>
                </div>

                <div className="ministries-category-info" style={{ color: MinistriesColor }}>
                  <PageContent className="content" content={toHTML(ninthMinistryInfo)} />
                </div>
              </div>
              : null}

            {/* TENTH MINISTRY */}
            {tenthMinistry ?
              <div className='ministries-category-box'>

                {/* Heading with Backing */}
                <div style={{ display: 'grid', }}>

                  {/* Backing */}
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="250px" viewBox="0 0 1035.000000 267.000000"
                    preserveAspectRatio="xMidYMid meet"
                    style={{
                      gridRow: '1 / 1',
                      gridColumn: '1 / 1',
                    }}>

                    <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                      fill={MinistriesColor} stroke="none">
                      <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null} />
                    </g>
                  </svg>

                  {/* Text */}
                  <div style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                    alignSelf: 'center',
                    textAlign: 'center',
                    width: '250px',
                    fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '1.8em' : '2.5em',
                    color: '#FFFFFF',
                    marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                    lineHeight: null,
                    fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                    fontFamily: MainFont,
                    fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                    letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.03em' : null
                  }}>
                    {tenthMinistryTitle}
                  </div>
                </div>

              <div className="ministries-category-info" style={{ color: MinistriesColor }}>
                <PageContent className="content" content={toHTML(tenthMinistryInfo)} />
              </div>
            </div>
          : null}
        </div>
      </div>
      }
    </div>
  )
}

MinistriesPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  firstMinistry: PropTypes.bool,
  firstMinistryTitle: PropTypes.string,
  firstMinistryInfo: PropTypes.string,
  secondMinistry: PropTypes.bool,
  secondMinistryTitle: PropTypes.string,
  secondMinistryInfo: PropTypes.string,
  thirdMinistry: PropTypes.bool,
  thirdMinistryTitle: PropTypes.string,
  thirdMinistryInfo: PropTypes.string,
  fourthMinistry: PropTypes.bool,
  fourthMinistryTitle: PropTypes.string,
  fourthMinistryInfo: PropTypes.string,
  fifthMinistry: PropTypes.bool,
  fifthMinistryTitle: PropTypes.string,
  fifthMinistryInfo: PropTypes.string,
  sixthMinistry: PropTypes.bool,
  sixthMinistryTitle: PropTypes.string,
  sixthMinistryInfo: PropTypes.string,
  seventhMinistry: PropTypes.bool,
  seventhMinistryTitle: PropTypes.string,
  seventhMinistryInfo: PropTypes.string,
  eighthMinistry: PropTypes.bool,
  eighthMinistryTitle: PropTypes.string,
  eighthMinistryInfo: PropTypes.string,
  ninthMinistry: PropTypes.bool,
  ninthMinistryTitle: PropTypes.string,
  ninthMinistryInfo: PropTypes.string,
  tenthMinistry: PropTypes.bool,
  tenthMinistryTitle: PropTypes.string,
  tenthMinistryInfo: PropTypes.string,
  contentComponent: PropTypes.func,
}

const MinistriesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <MinistriesPageTemplate
        backgroundimage={frontmatter.backgroundimage}
        firstMinistry={frontmatter.firstMinistry}
        firstMinistryTitle={frontmatter.firstMinistryTitle}
        firstMinistryInfo={frontmatter.firstMinistryInfo}
        secondMinistry={frontmatter.secondMinistry}
        secondMinistryTitle={frontmatter.secondMinistryTitle}
        secondMinistryInfo={frontmatter.secondMinistryInfo}
        thirdMinistry={frontmatter.thirdMinistry}
        thirdMinistryTitle={frontmatter.thirdMinistryTitle}
        thirdMinistryInfo={frontmatter.thirdMinistryInfo}
        fourthMinistry={frontmatter.fourthMinistry}
        fourthMinistryTitle={frontmatter.fourthMinistryTitle}
        fourthMinistryInfo={frontmatter.fourthMinistryInfo}
        fifthMinistry={frontmatter.fifthMinistry}
        fifthMinistryTitle={frontmatter.fifthMinistryTitle}
        fifthMinistryInfo={frontmatter.fifthMinistryInfo}
        sixthMinistry={frontmatter.sixthMinistry}
        sixthMinistryTitle={frontmatter.sixthMinistryTitle}
        sixthMinistryInfo={frontmatter.sixthMinistryInfo}
        seventhMinistry={frontmatter.seventhMinistry}
        seventhMinistryTitle={frontmatter.seventhMinistryTitle}
        seventhMinistryInfo={frontmatter.seventhMinistryInfo}
        eighthMinistry={frontmatter.eighthMinistry}
        eighthMinistryTitle={frontmatter.eighthMinistryTitle}
        eighthMinistryInfo={frontmatter.eighthMinistryInfo}
        ninthMinistry={frontmatter.ninthMinistry}
        ninthMinistryTitle={frontmatter.ninthMinistryTitle}
        ninthMinistryInfo={frontmatter.ninthMinistryInfo}
        tenthMinistry={frontmatter.tenthMinistry}
        tenthMinistryTitle={frontmatter.tenthMinistryTitle}
        tenthMinistryInfo={frontmatter.tenthMinistryInfo}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

MinistriesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default MinistriesPage

export const ministriesPageQuery = graphql`
  query MinistriesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "ministries-page" } }) {
      html
      frontmatter {
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        firstMinistry
        firstMinistryTitle
        firstMinistryInfo
        secondMinistry
        secondMinistryTitle
        secondMinistryInfo
        thirdMinistry
        thirdMinistryTitle
        thirdMinistryInfo
        fourthMinistry
        fourthMinistryTitle
        fourthMinistryInfo
        fifthMinistry
        fifthMinistryTitle
        fifthMinistryInfo
        sixthMinistry
        sixthMinistryTitle
        sixthMinistryInfo
        seventhMinistry
        seventhMinistryTitle
        seventhMinistryInfo
        eighthMinistry
        eighthMinistryTitle
        eighthMinistryInfo
        ninthMinistry
        ninthMinistryTitle
        ninthMinistryInfo
        tenthMinistry
        tenthMinistryTitle
        tenthMinistryInfo
      }
    }
  }
`